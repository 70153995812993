import { useState } from 'react';
import Copy from '../../../../../Icons/Copy';
import DialogComponent from '../../../../../utils/UIElements/Dialog.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';
import { openSnackbar } from '../../../../../Redux/Slices/main.ts';
import { useAppDispatch, useAppSelector } from '../../../../../hooks.ts';
import { changeNonField } from '../../../../../Redux/Slices/addCampaign.ts';
import AutoComplete from "../../Fields/AutoComplete";

export default function CopyAdsButton(): JSX.Element {
	const dispatch = useAppDispatch();
	const { ads } = useAppSelector(({ addCampaign }) => addCampaign);
	const { network } = useAppSelector((state) => state.campaignDetails);
	const [dialogOpen, toggleDialog] = useState<boolean>(false);
	const [campaignsLoading, setCampaignsLoading] = useState<boolean>(false);
	const [campaignsList, setCampaignsList] = useState<{ value: string, label: string }[]>([]);
	const [selectedCampaign, setSelectedCampaign] = useState<string>('');
	const chooseCampaignCopyAds = () => {
		setCampaignsLoading(true);
		toggleDialog(true);
		pullCampaignsList();
	};

	const cancelDialog = () => {
		toggleDialog(false);
		setSelectedCampaign('');
		setCampaignsList([]);
	};

	const handleResponse = (list: { campaignName: string, campaignID: string }[]) =>
		list.map((item) => ({ label: item.campaignName, value: item.campaignID }));
	const pullCampaignsList = () =>
		networkRequest('api/v1/campaign/pull', { network }, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && response.length) {
					setCampaignsList([
						...handleResponse(response),
					]);
				}
				setCampaignsLoading(false);
			}
		);

	const getSelectedCampaign = (campaignName: string) => {
		const selectedCampaign = campaignsList.find((campaign) => campaign.label === campaignName);
		if (selectedCampaign) {
			return selectedCampaign.value;
		}
		return '';
	}

	const copyAds = async () => {
		const campaignID = getSelectedCampaign(selectedCampaign);
		if (!campaignID) {
			dispatch(openSnackbar(
				{ children: 'Please select a campaign to copy ads from', severity: 'error' }
			));
			return;
		}
		await networkRequest('api/v1/campaign/ad/pull', { campaignID: campaignID, network }, 'POST')
		.then((response) => response.json())
		.then((response) => {
			if (response && response.length) {
				handleCopyAds(response);
			}
		}
		);
		cancelDialog();
	};

	const handleCopyAds = (adsList: object[]) => {
		const newAds = adsList.map(newAd => ({
			image: newAd.thumbnailUrl,
			headline: newAd.title,
			description: newAd.description,
			callToAction: newAd.cta || 'NONE',
			fileType: newAd.fileType,
			uuid: crypto.randomUUID(),
		}));
		dispatch(changeNonField({ key: 'ads', value: [...ads, ...newAds]}));
	};

	return (
		<>
			<DialogComponent
				loading={campaignsLoading}
				title="Select Campaign to Copy Ads from"
				useConfirm={copyAds}
				useCancel={cancelDialog}
				dialogOpen={dialogOpen}
			>
				{campaignsLoading ? <FormLoader /> :
					<>
						{campaignsList.length > 0 ?
							<AutoComplete
								onChange={(event, value) => {
									setSelectedCampaign(value)
								}}
								label="Click here to either search or select a campaign to copy ads from"
								value={selectedCampaign}
								name="campaigns-list"
								options={campaignsList}
								size={{m: '10', width: '100%'}}
							/>
							: 'We could not find any campaigns to copy ads from. Please create a new campaign first.'
						}
					</>
					}
			</DialogComponent>
			<button type="button" className="add-campaign-copy-ads-button" onClick={() => chooseCampaignCopyAds()}>
				<Copy className="add-campaign-copy-ads-icon" />
				Copy Ads
			</button>
		</>
	);
}
