import {useMemo, useState} from 'react';
import find from 'lodash/find';
import {IconButton, Tooltip} from "@mui/material";
import Select from "../../Fields/Select";
import Input from "../../Fields/Input";
import UploadAds from "./UploadAds";
import Add from "../../../../../Icons/Add";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {changeNonField} from "../../../../../Redux/Slices/addCampaign";
import { isRevContent } from '../../../../../utils/network-utils.ts';
import Minus from "../../../../../Icons/Minus";
import {useErrorHelper} from "../../../../../utils/useErrorHelper";
import {callToActionOptions} from "./CallToActionOptions.tsx";

const generateAds = (images: Array<object>, headlines: Array<object>, descriptions: Array<object>, callToAction: string) => {
	const result = [];
	images.forEach(({value: image, fileType, thumbnail}) => {
		headlines.forEach(({value: headline}) => {
			descriptions.forEach(({value: description}) => {
				if (image && headline && callToAction) {
					const ad = {
						image,
						headline,
						description,
						callToAction: fileType === 'video' ? 'NONE' : callToAction,
						fileType,
						thumbnail,
						uuid: crypto.randomUUID(),
					};
					result.push(ad);
				}
			});
		});
	});
	return result;
}

const emptyObj = (value = '') => ({
	value,
	uuid: crypto.randomUUID(),
});

const setValWithoutMutation = (array: Array<object>, value: string, uuid: string) => {
	const nonMutableArray = JSON.parse(JSON.stringify(array));
	const index = nonMutableArray.findIndex(({uuid: id}) => id === uuid);
	nonMutableArray[index].value = value;
	return nonMutableArray;
}

const removeValWithoutMutation = (array: Array<object>, value: string, uuid: string) => {
	const nonMutableArray = JSON.parse(JSON.stringify(array));
	const index = nonMutableArray.findIndex(({uuid: id}) => id === uuid);
	nonMutableArray.splice(index, 1);
	return nonMutableArray;
}

interface HeadlineProps {
	headlines: Array<object>,
	changeHeadlines: (value: Array<object>) => void,
	removeHeadline: (value: number) => void,
}

function Headlines({headlines, changeHeadlines, removeHeadline}: HeadlineProps) {
	const onChange = (value: string, uuid) => {
		let newArray;
		if (value === '' && headlines.length > 1 && uuid !== headlines[headlines.length - 1].uuid) {
			newArray = removeValWithoutMutation(headlines, value, uuid);
		} else {
			newArray = setValWithoutMutation(headlines, value, uuid);
		}
		changeHeadlines(newArray);
	};
	return headlines.map(({value: headline, uuid}, index) => (
		<div key={uuid} className="add-campaign-field-row">
			<Input
				onChange={({target: {value}}) => onChange(value, uuid)}
				label="Headline"
				value={headline}
				size={{m: '12px', width: 'calc(100% - 24px)'}}
				name={`headline-${index}`}
				multiline
			/>
			{index !== 0 && (
				<span
					className="remove-add-campaign-field-row"
					onClick={() => removeHeadline(index)}
				>
					<Minus/>
				</span>
			)}
		</div>
	))
}

interface DescriptionProps {
	descriptions: Array<object>,
	changeDescriptions: (value: Array<object>) => void,
	removeDescription: (value: number) => void,
}

function Descriptions({descriptions, changeDescriptions, removeDescription}: DescriptionProps) {
	const onChange = (value: string, uuid) => {
		let newArray;
		if (value === '' && descriptions.length > 1 && uuid !== descriptions[descriptions.length - 1].uuid) {
			newArray = removeValWithoutMutation(descriptions, value, uuid);
		} else {
			newArray = setValWithoutMutation(descriptions, value, uuid);
		}
		changeDescriptions(newArray);
	};
	return descriptions.map(({value: description, uuid}, index) => (
		<div key={uuid} className="add-campaign-field-row">
			<Input
				onChange={({target: {value}}) => onChange(value, uuid)}
				label="Description"
				value={description}
				size={{m: '12px', width: 'calc(100% - 24px)'}}
				name={`description-${index}`}
				multiline
			/>
			{index !== 0 && (
				<span
					className="remove-add-campaign-field-row"
					onClick={() => removeDescription(index)}
				>
					<Minus/>
				</span>
			)}
		</div>
	))
}

export default function AddAdsSection(): JSX.Element {
	const dispatch = useAppDispatch();
	const errorHelper = useErrorHelper();
	const {ads} = useAppSelector(({addCampaign}) => addCampaign);
	const { network } = useAppSelector((state) => state.campaignDetails);
	const [headlines, setHeadlines] = useState<Record<string, unknown>[]>([emptyObj()]);
	const [descriptions, setDescriptions] = useState<Record<string, unknown>[]>([emptyObj()]);
	const [images, setImages] = useState<Record<string, unknown>[]>([]);
	const [callToAction, setCallToAction] = useState<string>('NONE');

	const adErrors = () => {
		const errors: string[] = [];
		if (!images.length) {
			errors.push('Please upload at least one image');
		}
		if (!headlines.every(({value}) => value)) {
			errors.push('All Headlines should be at least 1 character long');
		}
		return errors;
	};

	const generateOnClick = () => {
		const errors = adErrors();
		if (errors.length) {
			errorHelper(errors);
			return;
		}
		const newAds = generateAds(images, headlines, descriptions, callToAction);
		dispatch(changeNonField({key: 'ads', value: [...ads, ...newAds]}));
		setHeadlines([emptyObj()]);
		setDescriptions([emptyObj()]);
		setImages([]);
		setCallToAction('NONE');
	}

	const removeHeadline = (index: number) => {
		const newHeadlines = [...headlines];
		newHeadlines.splice(index, 1);
		setHeadlines(newHeadlines);
	}

	const removeDescription = (index: number) => {
		const newDescriptions = [...descriptions];
		newDescriptions.splice(index, 1);
		setDescriptions(newDescriptions);
	}

	const isValid = useMemo(() => (adErrors().length === 0), [images, headlines, descriptions]);

	return (
		<div className="add-campaign-add-ads-container">
			<div className="add-ads-titles">
				Images
				<div className="hr-bar"/>
			</div>
			<div className="add-ads-images">
				{images.map(imageObj => (
					<div
						key={imageObj.value}
						onClick={() => setImages(images.filter(({value}: { value: string }) => value !== imageObj.value))}
					>
						<img
							src={(imageObj.fileType === 'video' && imageObj.value.split('.').pop() !== 'gif')
								? imageObj.thumbnail : imageObj.value}
							alt=""
						/>
						<div className="image-overlay"> x</div>
					</div>
				))}
			</div>
			<UploadAds
				images={images}
				changeImages={setImages}
			/>
			<div className="add-ads-titles">
				Headlines
				<div className="hr-bar"/>
			</div>
			<Headlines
				headlines={headlines}
				changeHeadlines={setHeadlines}
				removeHeadline={removeHeadline}
			/>
			<div className="add-ad-text-button-container">
				<IconButton
					id="add-ad-text-button"
					className="add-ad-text-button"
					onClick={() => setHeadlines([...headlines, emptyObj()])}
				>
					<Add className="links-add"/>
				</IconButton>
				New Headline
			</div>
			<div className="add-ads-titles">
				Descriptions
				<div className="hr-bar"/>
			</div>
			<Descriptions
				descriptions={descriptions}
				changeDescriptions={setDescriptions}
				removeDescription={removeDescription}
			/>
			<div className="add-ad-text-button-container">
				<IconButton
					id="add-description-button"
					className="add-ad-text-button"
					onClick={() => setDescriptions([...descriptions, emptyObj()])}
				>
					<Add className="links-add"/>
				</IconButton>
				New Description
			</div>
			{!isRevContent(network) && (!images.length || find(images, ['fileType', 'image'])) &&
				<>
					<div className="add-ads-titles">
						Call To Action
						<div className="hr-bar"/>
					</div>
					<div className="add-campaign-field-row">
						<Select
							onChange={({target: {value}}) => setCallToAction(value)}
							label="Call To Action"
							value={callToAction}
							name="add-campaign-cta"
							options={callToActionOptions}
							size={{m: '12px', width: 'calc(100% - 24px)'}}
						/>
					</div>
				</>}
			<Tooltip title={isValid ? 'Click to generate ads' : 'Disabled: Fill in all required fields to generate ads.'} placement="top">
				<button
					type="button"
					disabled={!isValid}
					id="generate-ads"
					className={`generate-ads ${!isValid ? 'bg-gray-200 text-gray-500' : ''}`}
					onClick={generateOnClick}
				>
					Generate Ads
				</button>
			</Tooltip>
			<div className="add-ads-token-text">
				Keywords: Country, Region, DMA, City, Platform, Day of Week
				<br/><br/>
				{'Format: ${Keyword:CapitalizationType}$'}
				<br/><br/>
				{'Example: Car Dealerships in ${city:capitalized}$, ${region:capitalized}$ Are Going Bankrupt Because Of This Free App.'}
			</div>
		</div>
	);
}
