import { isRevContent } from '@/utils/network-utils.ts'
import { Breadcrumbs, Button, IconButton, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'
import List from '../../../../../../Icons/List.tsx'
import Lock from '../../../../../../Icons/Lock.tsx'
import { updateUserSettings } from '../../../../../../Redux/Slices/main.ts'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks.ts'
import { commonColumns } from '../../../../../../utils/commonColumns.tsx'
import { formatPercent } from '../../../../../../utils/formatNumbers.ts'
import { CurrencyComparator } from '../../../../../../utils/tables-utils.tsx'
import ReportTable from '../../../../../DataTable/ReportTable.tsx'
import Toggle from '../../../../../FormComponents/Toggle.tsx'
import { ImageCell } from '../shared/ImageCell.tsx'
import SitePresellTable from './SitePresellTable.tsx'

export const RoutingIcon = (props: { row: { routing: boolean } }) =>
	props.row.routing ? (
		<div className="status-circle-container">
			<Tooltip title="This combination has Presell routing settings">
				<div className="ml-[5px] flex">
					<Lock className="size-6 fill-[#4285F4]" />
				</div>
			</Tooltip>
		</div>
	) : null

const StatusCircle = (props: Record<string, any>) =>
	props.row.id ? (
		<div className="status-circle-container">
			<Tooltip title={props.row.status === true ? 'Active' : 'Paused'}>
				<div className={`status-circle-${props.row.status === true ? 'active' : 'inactive'}`} />
			</Tooltip>
		</div>
	) : (
		''
	)

export default function ImageSitePresellV2() {
	const dispatch = useAppDispatch()
	const { estimatedSpends, network } = useAppSelector(state => state.campaignDetails)
	const { redirectOnRouting } = useAppSelector(state => state.main.userSettings)
	const filterStore = useAppSelector(state => state.filters)
	const [selectedReport, setSelectedReport] = useState('images')
	const [selectedImage, setSelectedImage] = useState('')

	useEffect(() => {
		setSelectedReport('images')
		setSelectedImage('')
	}, [filterStore])

	const changeReportButton = (params: Record<string, any>, report: string) =>
		params.row.id ? (
			<IconButton
				onClick={() => {
					if (report === 'sites') {
						setSelectedImage(params.row.ad_id)
					}
					setSelectedReport(report)
				}}
			>
				<List className="report-list-icon" />
			</IconButton>
		) : (
			''
		)

	const columns = [
		{
			field: 'sites',
			headerName: isRevContent(network) ? 'Widgets' : 'Sites',
			width: 90,
			renderCell: params => changeReportButton(params, 'sites'),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 90,
			renderCell: params => StatusCircle(params),
		},
		{
			field: 'routing',
			headerName: 'Routing',
			width: 90,
			renderCell: RoutingIcon,
		},
		{
			field: 'image',
			headerName: 'Image',
			width: 124,
			renderCell: params => (params.row.id ? <ImageCell url={params.value} /> : ''),
		},
		{
			field: 'headline',
			headerName: 'Headline',
			width: 250,
		},
		...commonColumns([
			'visits',
			'clicks',
			'presell_ctr',
			'conversions',
			'epc',
			'spend',
			'revenue',
			'profit',
			'profit_percent',
			'cvr',
		]),
		{
			field: 'network_ctr',
			headerName: 'Network CTR',
			width: 150,
			type: 'number',
			renderCell: params => formatPercent(params.value),
			sortComparator: CurrencyComparator,
		},
	] satisfies GridColDef[]

	const aggregationModel = {
		sites: 'blankColumn',
		status: 'blankColumn',
		routing: 'blankColumn',
		image: 'blankColumn',
		headline: 'blankColumn',
		presells: 'blankColumn',
		site_name: 'blankColumn',
		presell_url: 'blankColumn',
		visits: 'sum',
		clicks: 'sum',
		conversions: 'sum',
		presell_ctr: 'avg',
		epc: 'avg',
		spend: 'sum',
		revenue: 'sum',
		profit: 'sum',
		profit_percent: 'profitPercent',
		cvr: 'avg',
		cpa: 'avg',
		average_cpc: 'avg',
		network_ctr: 'avg',
		maximusProfit: 'sum',
		maximusProfitPercent: 'maximusProfitPercent',
		maximusClicks: 'sum',
		estimatedSpend: 'sum',
		maximusRevenue: 'sum',
		maximusConversions: 'sum',
	}

	return (
		<div className="campaign-details-report">
			<header className="campaign-details-report-header">
				<Breadcrumbs sx={{ margin: 0 }}>
					<Tooltip title={'Back to images'}>
						<Button
							sx={{
								textTransform: 'capitalize',
								margin: '0 -4px',
								padding: '0 8px',
								minWidth: 0,
								...(selectedReport === 'images' && { color: '#000e !important' }),
							}}
							disabled={selectedReport === 'images'}
							onClick={() => {
								setSelectedReport('images')
								setSelectedImage('')
							}}
						>
							{selectedImage ? `Image: ${selectedImage}` : 'Images'}
						</Button>
					</Tooltip>
				</Breadcrumbs>

				{selectedReport === 'presells' && (
					<Toggle
						label={`Redirect to ${isRevContent(network) ? 'Widget' : 'Site'} level on Routing`}
						name="redirect-on-routing"
						value={redirectOnRouting}
						onChange={() => {
							dispatch(updateUserSettings({ key: 'redirectOnRouting', value: !redirectOnRouting }))
							localStorage.setItem(
								'userSettings_redirectOnRouting',
								(!redirectOnRouting).toString()
							)
						}}
					/>
				)}
			</header>

			{!estimatedSpends && (
				<div className="states-report-disclaimer w-full text-center font-bold">
					* This report is built <span className="underline">using estimates</span> so it may not match campaigns that use network data *
				</div>
			)}

			{selectedReport === 'sites' ? (
				<SitePresellTable selectedAdId={selectedImage} aggregationModel={aggregationModel} />
			) : (
				<ReportTable
					endpoint="api/v1/campaign/reporting/imagesitepresell"
					columns={columns}
					tableName={`Image/${isRevContent(network) ? 'Widget' : 'Site'}/Presell`}
					aggregationModel={aggregationModel}
				/>
			)}
		</div>
	)
}
