import { AddCampaignState } from '../../../../../Redux/Slices/addCampaign.ts';
import { Network } from '../../../../../utils/network-utils.ts';
import {ValidateStepProps} from "../StepsValidation.tsx";

export const validateAds = (ads: AddCampaignState['ads'], network: Network): ValidateStepProps => {
	const errors: ValidateStepProps = {fields: {}};
	if (!ads || ads.length === 0) {
		errors.fields.ads = 'You should generate at least one ad.';
	}
	return errors;
};
