import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';

const columns = ([
	{
		field: 'os', headerName: 'OS', width: 150,
	},
	...commonColumns(
		[
			'impressions',
			'clicks',
			'conversions',
			'average_cpc',
			'epc',
			'ctr',
			'cvr',
			'cpa',
			'roas',
			'revenue',
			'spend',
			'profit',
			'profit_percent',
		]),
]);

export default function OSTargeting() {
	return (
		<div className="campaign-details-report">
			<ReportTable
				columns={columns}
				tableName="OS Targeting"
				endpoint="api/v1/campaign/reporting/os"
				aggregationModel={{
					impressions: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					epc: 'avg',
					average_cpc: 'avg',
					ctr: 'avg',
					cpa: 'avg',
					profit: 'sum',
					profit_percent: 'profitPercent',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					cvr: 'avg',
					roas: 'avg',
					spend: 'sum',
					revenue: 'sum',
					os: 'blankColumn',
				}}
			/>
		</div>
	);
}
