import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { modifyAdsForParams } from '../../../AddCampaign/Components/SubmitCampaign.tsx';
import { resetAddCampaign } from '@/Redux/Slices/addCampaign.ts';
import {validateAds} from "../../../AddCampaign/Components/Validation/Ads.tsx";

export const useSubmitAds = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network } = useAppSelector((state) => state.campaignDetails);
	const ads = useAppSelector((state) => state.addCampaign.ads);

	return async () =>  {
		if (validateAds(ads).fields.ads) {
			dispatch(openSnackbar(
				{
					children: validateAds(ads).fields.ads,
					severity: 'error'
				},
			));
			document.getElementById('generate-ads')?.scrollIntoView();
			return;
		}

		await networkRequest('api/v1/campaign/ad/create', {
			campaignID,
			network,
			ads: modifyAdsForParams(ads),
		}, 'POST')
		.then((response) => response.json())
		.then((response) => {
				if (response && typeof response === 'object' && response.success === true) {
					dispatch({ type: 'campaignDetails/closeDialog' });
					dispatch(resetAddCampaign({ network }));
				} else {
					dispatch(openSnackbar(
						{
							children: response.message || 'Something went wrong. Please try again',
							severity: 'error'
						},
					));
				}
			}
		)
		.catch(() => {
			dispatch(openSnackbar(
				{
					children: 'Something went wrong. Please try again',
					severity: 'error'
				},
			));
		});
	}
}
