import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Glance from '../../../Glance';
import Toggle from '../../../FormComponents/Toggle';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import networkRequest, { useCancellableNetworkRequest } from '../../../../utils/networkRequest';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import MaximusLoader from '../../../Loaders/MaximusLoader.tsx';
import { networkConditionalFormatObject } from "../utils/networkConditionals";
import { refreshAll } from '@/Redux/Slices/filters.ts';
import {updateArchiveStatus} from "../Requests/updateArchiveStatus";
import {updateCampaignStatus} from "../Requests/updateCampaignStatus";
import CampaignProcessingDialog from '../Settings/CampaignProcessingDialog.tsx';
import { useNoCampaignIDError } from '../useCampaignDetails.tsx';
import EstimatedSpendToggle from "./EstimatedSpendToggle";
import DialogComponent from '../../../../utils/UIElements/Dialog.tsx';
import { useDebouncedEffect } from '@/utils/useDebouncedEffect.tsx';
import { changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import { disableFFStatus } from './helper.ts';
import { getNetworkFromName, isRevContent, Network } from '@/utils/network-utils.ts';

const emptyData = {
	"clicks": 0,
	maximusClicks: 0,
	"conversions": 0,
	maximusConversions: 0,
	"revenue": 0.00,
	maximusRevenue: 0.00,
	"spend": 0.00,
	estimatedSpend: 0.00,
	"profit": 0.00,
	maximusProfit: 0.00,
	"profit_percent": 0.00,
	maximusProfitPercent: 0.00,
	"budget": 0.00,
	"cpc": 0.00,
	"epc": 0.00,
	maximusEpc: 0.00,
	"cvr": 0.00,
	maximusCvr: 0.00,
	"act": 0,
	"average_cpc": 0.00,
	maximusAverageCpc: 0.00,
	'targetCPA': 0.00,
};

const formatData = (response, estimatedSpends: boolean, network: Network) => {
	const dataKeys = estimatedSpends ? [
		'maximusClicks', 'maximusConversions', 'maximusRevenue', 'estimatedSpend', 'maximusProfit', 'maximusProfitPercent',
		'budget', 'maximusEpc', 'maximusCvr', 'maximusAverageCpc', 'act',
	] : [
		'clicks', 'conversions', 'revenue', 'spend', 'profit', 'profit_percent',
		'budget', 'epc', 'cvr', 'average_cpc', 'act',
	];
	if (response.bid_strategy === '1' || response.bid_strategy === '2' || isRevContent(network)) {
		dataKeys.splice(7, 0, 'cpc');
	} else if (response.bid_strategy === '4') {
		dataKeys.splice(7, 0, 'targetCPA');
	}
	const finalData = {};
	dataKeys.forEach((key) => {
		finalData[key] = response[key] || 0;
	});
	return finalData;
};

// defaultDataTypes should include data keys only that are used in all network. network specific keys should be separated.
const defaultDataTypes = {
	"clicks": "number",
	maximusClicks: 'number',
	"conversions": "number",
	maximusConversions: 'number',
	"revenue": "currency",
	maximusRevenue: 'currency',
	"spend": "currency",
	estimatedSpend: 'currency',
	"profit": "currency",
	maximusProfit: 'currency',
	"profit_percent": "percent",
	maximusProfitPercent: 'percent',
	"budget": "currency",
	"cpc": "currency",
	"epc": "currency",
	maximusEpc: 'currency',
	"cvr": "percent",
	maximusCvr: 'percent',
	"average_cpc": "currency",
	maximusAverageCpc: 'currency',
	"act": "custom",
	'targetCPA': 'currency',
};

const taboolaDataTypes = {};
const outbrainDataTypes = {};

// Data names can include data names of every network combination
const dataNames = {
	"clicks": "Clicks",
	maximusClicks: 'Clicks',
	"conversions": "Conversions",
	maximusConversions: 'Conversions',
	"revenue": "Revenue",
	maximusRevenue: 'Revenue',
	"spend": "Spend",
	estimatedSpend: 'Spend',
	"profit": "Profit",
	maximusProfit: 'Profit',
	"profit_percent": "Profit %",
	maximusProfitPercent: 'Profit %',
	"budget": "Budget",
	"cpc": "CPC",
	"epc": "EPC",
	maximusEpc: 'EPC',
	"cvr": "CVR",
	maximusCvr: 'CVR',
	"average_cpc": "Average CPC",
	maximusAverageCpc: 'Average CPC',
	"act": "ACT",
	'targetCPA': 'Target CPA',
};

export default function ManageGlance() {
	const [glanceData, setGlanceData] = useState<{ [index: string]: number }>(emptyData);
	const [networkDataTypes, setNetworkDataTypes] = useState<object>(defaultDataTypes);
	const filters = useAppSelector((state) => state.filters);
	const [loading, setLoading] = useState<boolean>(true);
	const [rejectedStatus, setRejectedStatus] = useState<boolean>(false);
	const [warningMessages, setWarningMessages] = useState<string[]>([]);
	const [confirmMessages, setConfirmMessages] = useState<string[]>([]);
	const [processingModalOpen, setProcessingModalOpen] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const {
		campaignStatus,
		campaignArchiveStatus,
		campaignID,
		networkCampaignID,
		usmID,
		campaignName,
		network,
		estimatedSpends,
	} = useAppSelector((state) => state.campaignDetails);
	const dispatch = useAppDispatch();
	const cancellableRequest = useCancellableNetworkRequest();
	const { id: campaignNameFromUrl } = useParams<{ id: string }>();

	const isCampaignProcessed = networkCampaignID && networkCampaignID !== 'NA' && networkCampaignID !== campaignID

	const formattedFilters = useMemo(() => {
		const formattedFilters = {};
		Object.keys(filters).forEach((filter) => {
			if (['startDate', 'endDate', 'weekDay', 'network', 'dateRange'].includes(filter) && filters[filter]) {
				if (filter === 'startDate' || filter === 'endDate') {
					formattedFilters[filter] = format(new Date(filters[filter]), 'yyyy-MM-dd');
				} else {
					formattedFilters[filter] = filters[filter];
				}
			}
		});
		return formattedFilters;
	}, [filters]);

	useEffect(() => {
		if (!campaignID) return;

		setNetworkDataTypes(
			networkConditionalFormatObject(
				{
					network,
					defaultObject: defaultDataTypes,
					outbrainObject: outbrainDataTypes,
					taboolaObject: taboolaDataTypes
				}
			)
		);
		pullStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaignID]);

	useEffect(() => {
		if (campaignNameFromUrl) {
			dispatch(changeCampaignDetails({ key: 'campaignName', value: campaignNameFromUrl }))
			dispatch(changeCampaignDetails({ key: 'network', value: getNetworkFromName(campaignNameFromUrl) }))
		}
	}, [campaignNameFromUrl]);

	useDebouncedEffect(() => {
		if (formattedFilters || filters.refreshAllData) {
			getGlanceData();
		}
	}, [formattedFilters, filters.refreshAllData], 100);

	const setNoCampaignIDError = useNoCampaignIDError();

	const getGlanceData = () => {
		setLoading(true);
		dispatch(refreshAll(false));
		dispatch(changeCampaignDetails({ key: 'networkCampaignID', value: '' }));
		cancellableRequest('api/v1/campaign/reporting/glance_v2', {
			...formattedFilters,
			...(campaignID ? { campaignID } : { name: campaignNameFromUrl }),
			network: getNetworkFromName(campaignNameFromUrl!),
		}, 'POST')
			.then((response) => {
				if (response && !response.ok) {
					setNoCampaignIDError();
					return;
				}
				return response?.json();
			})
			.then((response) => {
				if (response && typeof response === 'object') {
					setGlanceData(formatData(response, estimatedSpends, network));
					dispatch(changeCampaignDetails({ key: 'campaignID', value: response.campaign_id || '' }));
					dispatch(changeCampaignDetails({ key: 'networkCampaignID', value: response.networkCampaignID || 'NA' }));
					dispatch(changeCampaignDetails({ key: 'subAccountID', value: response.subAccountID || 'NA' }));
					dispatch(changeCampaignDetails({ key: 'usmID', value: response.usmID || 'NA' }));
					dispatch(changeCampaignDetails({ key: 'startDate', value: response.start_date ? format(response.start_date, 'MM/dd/yyyy') : '' }));
					dispatch(changeSettingsField({ name: 'budget', value: response.budget || 0 }));
					dispatch(changeSettingsField({ name: 'cpc', value: response.cpc || 0 }));
					setLoading(false);
				}
			})
			.catch(() => {
				setGlanceData(emptyData);
				setLoading(false);
			});
	};

	function pullStatus() {
		networkRequest('api/v1/campaign/status/pull', { campaignID, network })
			.then((response) => response.json())
			.then((response) => {
				if (response) {
					const isRejected = response.text_status === 'Rejected';
					dispatch(changeCampaignDetails({ key: 'campaignStatus', value: !(isRejected || !response.status) }));
					setRejectedStatus(isRejected);
				}
			});
	}

	return (
		<div className="campaign-details-glance">
			<div className="campaign-details-glance-header">
				<div className="campaign-details-glance-header-left">
					{campaignName}
				</div>
				{loading ?
					<div className="campaign-details-glance-header-right"/>
					: <div
							className="campaign-details-glance-header-right"
							onClick={e => {
								if (!isCampaignProcessed && !processingModalOpen) {
									e.preventDefault()
									e.stopPropagation()
									setProcessingModalOpen(true)
								}
							}}
						>
						<EstimatedSpendToggle loading={updateLoading} setLoading={setUpdateLoading} />
						<Toggle
							label="Archive Status"
							name="archive-status"
							onChange={() => {
								setUpdateLoading(true)
								updateArchiveStatus(!campaignArchiveStatus, dispatch, campaignID, network, campaignStatus)
									.finally(() => setUpdateLoading(false))
							}}
							disabled={updateLoading}
							value={campaignArchiveStatus}
						/>
						<Toggle
							label="Campaign Status"
							name="campaign-status"
							onChange={() => {
								setUpdateLoading(true)
								updateCampaignStatus(!campaignStatus, dispatch, campaignID, network, networkCampaignID, usmID, setWarningMessages, setConfirmMessages)
									.finally(() => setUpdateLoading(false))
							}}
							value={campaignStatus}
							disabled={campaignArchiveStatus || rejectedStatus || updateLoading}
							tooltipTitle={rejectedStatus ?
								'Taboola prevents users from editing the status of rejected campaigns. If you would like to hide this campaign in Maximus, you can archive it' : ''}
						/>
						<DialogComponent
							dialogOpen={!!warningMessages.length || !!confirmMessages.length}
							title={confirmMessages.length ? 'Confirm' : 'Warning'}
							useConfirm={confirmMessages.length ? async () => {
								await disableFFStatus(campaignID, network, dispatch);
								setWarningMessages([]);
								setConfirmMessages([]);
							} : null}
							useCancel={() => {
								setWarningMessages([]);
								setConfirmMessages([]);
							}}>
							<>
								{warningMessages.map(msg => <p>{msg}</p>)}
								{confirmMessages.map(msg => <p>{msg}</p>)}
							</>
						</DialogComponent>

						{!!processingModalOpen && (
							<CampaignProcessingDialog
								title='Checking campaign processing status'
								onClose={() => setProcessingModalOpen(false)}
							/>
						)}
					</div>
				}
			</div>
			{loading ? <MaximusLoader size={18} /> :
				<Glance data={glanceData} dataTypes={networkDataTypes} dataNames={dataNames} />}
		</div>
	)
}
