import Toggle from '@/Components/FormComponents/Toggle';
import Check from '@/Icons/Check';
import Close from '@/Icons/Close';
import Edit from '@/Icons/Edit';
import Warning from '@/Icons/Warning';
import { openSnackbar } from '@/Redux/Slices/main';
import { useAppDispatch } from '@/hooks';
import { formatCurrency } from '@/utils/formatNumbers';
import { isRevContent, isTaboola } from '@/utils/network-utils';
import { CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { GridCellModes, GridRowId } from '@mui/x-data-grid-premium';
import lodash from 'lodash';
import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

const { startCase } = lodash;

const StatusIcon = ({ status }) => (
  <Tooltip title={startCase(status)} placement="top">
		<div>
			<Warning className={`manage-name-status ${status.toLowerCase().indexOf('pending') === 0 ? 'pending' : 'rejected'}`}/>
		</div>
  </Tooltip>
);

export const CampaignNameCell = (params) => {
	const checkForWarningToShow =  (row) => {
		if (isTaboola(row.network_id)) {
			return row.textStatus && !['RUNNING', 'DEPLETED_MONTHLY', 'PAUSED'].includes(params.row.textStatus.toUpperCase());
		}
		if (isRevContent(row.network_id)) {
			return row.text_status && ['balance_issue', 'budget_exhausted'].includes(params.row.text_status);
		}
	};

	return (
		<div className="manage-report-campaign-name">
			<Link className="manage-name-link" to={`/campaign_details/${params?.value}`}
						state={{campaignID: params?.row?.campaign_id}}>{params.value}</Link>
			{checkForWarningToShow(params.row) && <StatusIcon status={params.row.text_status}/>}
		</div>
	);
}

const editCellHandlers = (cellModesModel, setCellModesModel) => {
  const handleEditClick = (id: GridRowId) => () => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { budget: { mode: GridCellModes.Edit } },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { budget: { mode: GridCellModes.View } },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setCellModesModel({
      ...cellModesModel,
      [id]: { budget: { mode: GridCellModes.View, ignoreModifications: true } },
    });
  };

  return { handleEditClick, handleSaveClick, handleCancelClick };
}

export function EditBudget({ row, onChange }: { row: Record<string, any>, onChange: (budget: number) => Promise<void> }) {
	const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState('')
	const isProcessed = !!row.network_campaign_id && row.network_campaign_id !== row.campaign_id

  const startEdit = () => {
		if (!isProcessed) {
			dispatch(openSnackbar({
				children: 'This campaign is still being processed by the network. Please try again in a few minutes.',
				severity: 'warning',
			}))
			return
		}
    setValue(row.budget)
    setIsEditing(true)
  }

  const handleValueChange = (value: string) => {
    const numericPattern = /^[0-9\b.]+$/
    const numberOfPeriods = (value.match(/\./g) || []).length
    const decimals = (value.split('.')[1] || '').length
    if (value === '' || (numericPattern.test(value) && numberOfPeriods <= 1 && decimals <= 2)) {
      setValue(value)
    }
  }

  const handleBudgetUpdate = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    await onChange(Number(value)).finally(() => {
      setIsLoading(false)
      setIsEditing(false)
    })
  }

  if (!row.id) return ''

  return (
    <form className="flex h-full items-center justify-end" onSubmit={handleBudgetUpdate}>
      {isEditing ? (
        <>
          <TextField
            inputProps={{ className: 'p-1' }}
            size="small"
            disabled={isLoading}
            value={value}
            onChange={(e) => handleValueChange(e.target.value)}
          />

          <IconButton key="close" size="small" color="error" disabled={isLoading} onClick={() => setIsEditing(false)}>
            <Close className={`size-5 ${isLoading && 'opacity-50'}`} />
          </IconButton>

          <IconButton key="check" size="small" color="success" disabled={isLoading} onClick={handleBudgetUpdate}>
            {isLoading
              ? <CircularProgress size={20} />
              : <Check className="size-5" />}
          </IconButton>
        </>
      ) : (
        <>
          <span className="mr-2 leading-none">{formatCurrency(row.budget)}</span>

          <IconButton key="edit" size="small" onClick={startEdit}>
            <Edit className="size-5" />
          </IconButton>
        </>
      )}
    </form>
  )
}

export const StatusToggle = (params: Record<string, any>, name: string, changeStatus, disabled = false) => {
  const campaignIsRejected = params.row.text_status === 'REJECTED';
  const isNotProcessed = !params.row.network_campaign_id || params.row.network_campaign_id === params.row.campaign_id
  return params.value !== undefined ? (
    <Toggle
      name={`${name}-${params.row.id}`}
      onChange={() => changeStatus(params.row.id, !params.value, params.row)}
      value={(!(campaignIsRejected || !params.value))}
      disabled={campaignIsRejected || isNotProcessed || disabled}
      tooltipTitle={
        campaignIsRejected ? 'Taboola prevents users from editing the status of rejected campaigns. If you would like to hide this campaign in Maximus, you can archive it'
        : isNotProcessed ? 'This campaign is still being processed by the network. Please try again in a few minutes.'
        : ''
      }
    />) : '';
}
