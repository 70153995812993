import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import { formatPercent } from '../../../../../utils/formatNumbers.ts';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import {CurrencyComparator} from "../../../../../utils/tables-utils.tsx";
import {GridColDef} from "@mui/x-data-grid-premium";
import {useAppSelector} from "../../../../../hooks.ts";

const getFormFromLandingURL = (url: string | undefined) => {
	if (!url) return '';
	const form = url.split('&').find((param) => param.includes('form='));
	if (!form) return url;
	return form.split('=')[1];

}

function columns (isOtto: boolean): GridColDef[] {
	return ([
		{
			field: 'url',
			headerName: 'Landing URL',
			width: 250,
			renderCell: (params) => (
				params?.value?.includes('http') ?
					<a target="_blank" className="manage-name-link" href={`https://${params.value}`}>
						{isOtto ? getFormFromLandingURL(params.value) : params.value}
					</a>
					: params.value
			),
		},
		{
			field: 'cvr',
			headerName: 'Landing CVR',
			width: 150,
			renderCell: (params) => formatPercent(params.value),
			sortComparator: CurrencyComparator,
			type: 'number',
		},
		...commonColumns([
			'visits',
			'clicks',
			'conversions',
			'epc',
			'spend',
			'revenue',
		]),
	]);
}

export default function Landing() {
	const { isOtto } = useAppSelector((state) => state.campaignDetails);

	return (
		<div className="campaign-details-report">
			<ReportTable
				columns={columns(isOtto)}
				tableName="Landing"
				endpoint="api/v1/campaign/reporting/landing"
				aggregationModel={{
					clicks: 'sum',
					visits: 'sum',
					conversions: 'sum',
					cpc: 'avg',
					cvr: 'avg',
					epc: 'avg',
					spend: 'sum',
					revenue: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					landing_ctr: 'avg',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					landing_url: 'blankColumn',
				}}
			/>
		</div>
	);
}
