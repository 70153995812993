import { CircularProgress } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Upload from '../../../../../Icons/Upload';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import {isRevContent, isTaboola, Network} from '@/utils/network-utils.ts';
import { networkRequestMultipart } from '@/utils/networkRequest.ts';

const MB = 1024 * 1024
const GB = 1024 * MB

function getFileDimensions(src, file, index, callback, thumbnailUrl = '') {
	if (file.type.split('/')[0] === 'image') {
		const fileName = `[IMG] ${file.name.split('.')[0]}`;
		callback(src, fileName, 0, 0, index, file.type.match('image/gif') ? 'video' : 'image');
	} else {
		const video = document.createElement('video');
		video.addEventListener('loadedmetadata', function () {
			const height = this.videoHeight;
			const width = this.videoWidth;
			const fileName = `[VID] ${file.name.split('.')[0]}`;
			callback(src, fileName, height, width, index, 'video', thumbnailUrl);
		}, false);
		video.src = src;
	}
}

function getVideoCover(file, seekTo = 0.0) {
	return new Promise((resolve, reject) => {
		const videoPlayer = document.createElement('video');
		videoPlayer.setAttribute('crossorigin', 'anonymous');
		videoPlayer.setAttribute('src', URL.createObjectURL(file));
		videoPlayer.load();
		videoPlayer.addEventListener('error', () => {
			// eslint-disable-next-line prefer-promise-reject-errors
			reject('Error when loading video file. Please try again.');
		});
		videoPlayer.addEventListener('loadedmetadata', () => {
			if (videoPlayer.duration < seekTo) {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Video is too short. Please upload another one.');
				return;
			}
			setTimeout(() => {
				videoPlayer.currentTime = seekTo;
			}, 200);
			videoPlayer.addEventListener('seeked', () => {
				const canvas = document.createElement('canvas');
				canvas.width = videoPlayer.videoWidth;
				canvas.height = videoPlayer.videoHeight;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
				ctx.canvas.toBlob(
					(blob) => {
						resolve(blob);
					},
					'image/jpeg',
					1,
				);
			});
		});
	});
}

export const getImageObj = (file: File): Promise<{ height: number, width: number }> => {
	return new Promise((resolve) => {
		const img = new Image();
		const reader = new FileReader();
		reader.onload = (event) => {
			img.src = event.target?.result || '';
		};

		reader.readAsDataURL(file);
		img.onload = () => {
			resolve({ height: img.height, width: img.width });
		};
	});
}

const getVideoObj = (file: File): Promise<{ height: number, width: number }> => {
	return new Promise((resolve) => {
		const video = document.createElement('video');
		const videoURL = URL.createObjectURL(file);

		video.addEventListener('loadedmetadata', function () {
			resolve({ height: this.videoHeight, width: this.videoWidth });
		}, false);
		video.src = videoURL;
	});
}

const validateFile = async (file: File, network: Network) => {
	const [type, extension] = file.type.toLowerCase().split('/')
	if (type === 'video') {
		if (isRevContent(network)) {
			return 'RevContent does not support video ads. Please upload an image.';
		}
		if (!['mp4', 'quicktime'].includes(extension)) {
			return 'Valid video formats are .mp4 and .mov. Please upload another video with the supported formats.'
		}
		const videoObj = await getVideoObj(file);
		if (file.size > 51 * MB) {
			return 'The video you\'ve uploaded is too large. Please make sure your video is less than 51Mb in size and try again.';
		}
		if (videoObj.width < 1067 || videoObj.height < 600) {
			return 'Looks like this video\'s dimensions are too small. Please make sure that this video is at least 1067x600px and try again';
		}
	} else if (file.type === 'image/gif') {
		if (isRevContent(network)) {
			return 'Valid image formats are .jpg, .jpeg, .png and .webp. Please upload another image with a valid format.'
		}
		const imageObj = await getImageObj(file);
		if (file.size > 5 * MB) {
			return 'The image or gif you\'ve uploaded is too large. Please make sure that the file is less than 5Mb in size and try again.'
		}
		if (imageObj.width < 500 || imageObj.height < 375) {
			return 'Looks like this image or gif\'s dimensions are too small. Please make sure that the dimensions are at least 500px x 375px and try again';
		}
		return '';
	} else if (type === 'image') {
		if (isRevContent(network)) {
			if (file.size > 2 * MB) {
				return 'Image file must be under 2MB. Please upload another image.'
			}
			if (!['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
				return 'Valid image formats are .jpg, .jpeg, .png and .webp. Please upload another image.'
			}
			const imageObj = await getImageObj(file);
			if (imageObj.width < 500 || imageObj.height < 375) {
				return 'Looks like this image\'s dimensions are too small. Please make sure that the dimensions are at least 500px x 375px and try again';
			}
			let aspectRatio = 0;
			if (imageObj.width > imageObj.height) {
				aspectRatio = imageObj.width / imageObj.height;
			} else {
				aspectRatio = imageObj.height / imageObj.width;
			}
			if (aspectRatio !== 4/3) {
				return 'The image\'s ratio should be 4:3. Please upload another image with the supported format.';
			}
		}
		if (isTaboola(network)) {
			if (!['jpg', 'jpeg', 'bmp', 'png', 'wbmp'].includes(extension)) {
				return 'Valid image formats for Taboola are .jpg, .bmp, .wbmp and .png. Please upload another image with the supported formats.';
			}
			if (file.size > 51 * MB) {
				return 'The image you\'ve uploaded is too large. Please make sure your image is less than 51Mb in size and try again.';
			}
			const imageObj = await getImageObj(file);
			if (imageObj.width < 400 || imageObj.height < 350) {
				return 'Looks like this image\'s dimensions are too small. Please make sure that the dimensions are at least 400x350 pixels and try again';
			}
		}
	}
	return '';
};

export default function UploadAds(
	{
		images,
		changeImages
	}: { images: Array<object>, changeImages: Dispatch<SetStateAction<any>> }): JSX.Element {
	const [newImages, setNewImages] = useState([]);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const { network } = useAppSelector(state => state.campaignDetails);
	const dispatch = useAppDispatch();

	function handleDrop(e: React.DragEvent<HTMLLabelElement>) {
		e.preventDefault()
		e.stopPropagation()
		if (loading) return false
		return handleUpload(e.dataTransfer.files)
	}

	const getEndpoint = (file: File, isThumbnail: boolean) => {
		if (file.type.match('video.*') || file.type.match('image/gif') || isThumbnail) {
			return 'api/v1/campaign/fileUpload/video';
		}
		return 'api/v1/campaign/fileUpload/image';
	}

	const uploadFile = async (file: File, isThumbnail = false) => {
		return networkRequestMultipart(
			getEndpoint(file, isThumbnail),
			{ file, network },
			'https://creatives.maximusx.app'
		)
			.then((response) => response.json())
			.catch(() => ({url: ''}));
	}

	const validateAds = async (fileArray: File[]) => {
		let validAd = true;
		let validationMessage = '';
		if (loading) {
			validAd = false;
		}
		for (const file of fileArray) {
			validationMessage = await validateFile(file, network);
			if (validationMessage) {
				validAd = false;
			}
		}
		if (!validAd) {
			dispatch(openSnackbar(
				{
					children: validationMessage || 'Something went wrong while uploading one or multiple ads. Please check your ads and try again',
					severity: 'error'
				}
			));
		}
		return validAd;
	};

	const createThumbnail = async (file, dimensions) => {
		const cover = await getVideoCover(file, 0.1);
		const newFile = new File([cover], 'videoThumbnail.jpg', { type: 'image/jpeg' });
		return await uploadFile(newFile, true);
	}

	const handleUpload = async (files: FileList | null) => {
		const fileArray = Object.values(files || {});
		const validAd = await validateAds(fileArray);
		if (!validAd) {
			return false;
		}
		setLoading(true);
		const promises = fileArray.map(async (file) => {
			const response = await uploadFile(file);
			return {data: response, file};
		});
		return handlePromises(promises);
	};

	const handlePromises = async (promises) => {
		Promise.all(promises)
			.then(async (responses) => {
				setUploadedImages(responses);
				for await (const [index, { data, file }] of responses.entries()) {
					if (data.url) {
						let thumbnailUrl = '';
						if (file.type.match('video.*')) {
							try {
								const dataThumbnail = await createThumbnail(file);
								thumbnailUrl = dataThumbnail.url || '';
							} catch (e) {
								dispatch(openSnackbar(
									{
										children: e || 'Something went wrong while uploading one or multiple ads. Please check your ads and try  again',
										severity: 'error'
									}
								));
								continue;
							}
						}
						getFileDimensions(data.url, file, index, handleResponse, thumbnailUrl);
						continue;
					}
					setUploadedImages((prevState) => prevState.filter((item) => item.file.name !== file.name));
					const errorMessages: string[] = []
					for (const key in data.errors || {}) {
						for (const error of data.errors[key]) {
							errorMessages.push(error)
						}
					}
					if (errorMessages.length) {
						const children = <ul>{errorMessages.map(error => <li key={error}>{error}</li>)}</ul>
						dispatch(openSnackbar({ children, severity: 'error' }))
					} else {
						const fallbackMsg = 'Something went wrong while uploading one or multiple ads. Please check your ads and try  again'
						dispatch(openSnackbar({ children: data.message || fallbackMsg, severity: 'error' }))
					}
				}
				setLoading(false);
			})
	};

	const handleResponse = async (src, fileName, height, width, index, fileType, thumbnail = '') => {
		const newImage = {
			value: src,
			width,
			height,
			fileType,
			thumbnail,
		};
		setNewImages((prevState) => [...prevState, newImage]);
	};

	useEffect(() => {
		if (newImages.length > 0 && uploadedImages.length > 0 && newImages.length === uploadedImages.length) {
			changeImages([...images, ...newImages]);
			setNewImages([]);
			setUploadedImages([]);
		}
	}, [newImages, uploadedImages]);

	return (
		<div className="add-campaign-upload-ads-container">
			<label
				className="add-ad-drag-drop-container"
				onDrop={handleDrop}
				onDragOver={e => void e.preventDefault()}
			>
				<input
					value=''
					style={{display: 'none'}}
					type="file"
					id="fileInput"
					multiple
					accept="video/*, image/*"
					onChange={(event) => handleUpload(event.target.files)}
				/>
				<div className="add-add-drop-icon-button">
					{loading
						? <CircularProgress/>
						: <Upload/>
					}
				</div>
				<div className="add-add-drop-title">
					Upload Images or Videos
				</div>
			</label>
		</div>
	);
}
